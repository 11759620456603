<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div class="text-end">
    <div v-show="!impiantoSocieta">
      <router-link
        :to="`/impianti`"
        class="btn text-hover-primary text-gray-800 fs-6 text-end p-0 m-0 pb-5 pe-4 mb-2"
      >
        <i class="bi bi-arrow-left text-gray-800 fs-6"></i>
        Lista impianti
      </router-link>
    </div>
  </div>
  <Form
    @submit="onSubmit"
    :validation-schema="schema"
    @invalid-submit="onInvalidSubmit"
    v-if="isLoaded"
  >
    <div class="accordion" id="accordionAnagraficaImpianto">
      <form autocomplete="off">
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaImpianto-headingOne"
            >
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaImpianto-collapseOne"
                aria-expanded="true"
                aria-controls="accordionAnagraficaImpianto-collapseOne"
              >
                Dati Impianto
              </button>
            </h6>
            <div
              id="accordionAnagraficaImpianto-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAnagraficaImpianto-headingOne"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Denominazione*</label
                        >
                        <Field
                          name="denominazione"
                          type="text"
                          class="form-control"
                          v-model="impianto.denominazione"
                          :disabled="onlyView"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="denominazione"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">Tipologia*</label>
                        <SelectInput
                          :options="tipologieImpianti"
                          name="tipo_impianto"
                          :value="impianto.tipo_impianto"
                          @changeSelect="impianto.tipo_impianto = $event"
                          :disabled="onlyView"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">Proprietà*</label>
                        <SelectInput
                          :options="proprietaImpianti"
                          name="tipo_proprieta"
                          :value="impianto.tipo_proprieta"
                          @changeSelect="impianto.tipo_proprieta = $event"
                          :disabled="onlyView"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Data costruzione impianto</label
                        >
                        <Datepicker
                          v-model="impianto.data_costruzione_impianto"
                          name="data_costruzione_impianto"
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          calendarCellClassName="fontData"
                          autoApply
                          :maxDate="new Date()"
                        />
                        <Field
                          name="data_costruzione_impianto"
                          v-model="impianto.data_costruzione_impianto"
                          type="hidden"
                          class="form-control"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_Scadenza_Permesso_Soggiorno"
                        />
                      </div>
                      <div class="row mt-2">
                        <div class="col-sm-3 mb-4">
                          <label class="fw-bold text-gray-600"
                            >Ente aggregato:</label
                          >
                          <div class="pt-2">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="impianto.ente_aggregato"
                              @click="
                                values.ente_aggregato = impianto.ente_aggregato
                              "
                            />
                            <!-- {{ impianto.ente_aggregato }} -->
                            <Field
                              name="ente_aggregato"
                              v-model="impianto.ente_aggregato"
                              type="hidden"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaImpianto-headingTwo"
            >
              <button
                class="accordion-button collapsed fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaImpianto-collapseTwo"
                aria-expanded="false"
                aria-controls="accordionAnagraficaImpianto-collapseTwo"
              >
                Ubicazione
              </button>
            </h6>
            <div
              id="accordionAnagraficaImpianto-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="accordionAnagraficaImpianto-headingTwo"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Provincia*</label>
                        <SelectInput
                          :options="province"
                          name="id_provincia"
                          :value="impianto.id_provincia"
                          v-model="impianto.id_provincia"
                          @changeSelect="changeselectedProvinciaP($event)"
                          :disabled="onlyView"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Comune*</label>

                        <!--<SelectInput
                        :options="comuni"
                        name="id_comune"
                        :disabled="selectedProvinciaL ? false : true"
                      />-->
                        <input
                          name="comune"
                          type="text"
                          class="form-control"
                          placeholder=""
                          aria-label=""
                          @input="
                            spliceComuniIstat();
                            setComuni(impianto.comune, impianto.id_provincia);
                          "
                          v-model="impianto.comune"
                          @keyup.delete="
                            spliceComuniIstat();
                            impianto.id_comune = 0;
                          "
                          :disabled="!selectedProvincia || onlyView"
                          autocomplete="off"
                        />
                        <ListComuni
                          v-if="comuniIstat"
                          :list="comuniIstat"
                          @hideList="spliceComuniIstat"
                          @selectedElement="
                            impianto.comune = $event.name;
                            spliceComuniIstat();
                            impianto.id_comune = $event.value;
                          "
                        ></ListComuni>
                        <Field
                          name="id_comune"
                          type="hidden"
                          v-model="impianto.id_comune"
                          class="form-control"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="id_comune"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-5">
                        <label class="fw-bold text-gray-600">Indirizzo*</label>
                        <Field
                          name="indirizzo"
                          type="text"
                          class="form-control"
                          v-model="impianto.indirizzo"
                          :disabled="onlyView"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="indirizzo"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">N° Civico*</label>
                        <Field
                          name="num_civico"
                          type="text"
                          class="form-control"
                          v-model="impianto.num_civico"
                          :disabled="onlyView"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="num_civico"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">C.A.P.*</label>
                        <Field
                          name="cap"
                          type="text"
                          class="form-control"
                          v-model="impianto.cap"
                          :disabled="onlyView"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="cap"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <div class="card shadow mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaImpianto-headingThree"
            >
              <button
                class="accordion-button collapsed fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaImpianto-collapseThree"
                aria-expanded="false"
                aria-controls="accordionAnagraficaImpianto-collapseThree"
              >
                Contatti
              </button>
            </h6>
            <div
              id="accordionAnagraficaImpianto-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="accordionAnagraficaImpianto-headingThree"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">Telefono*</label>
                        <Field
                          name="telefono"
                          type="text"
                          class="form-control"
                          v-model="impianto.telefono"
                          :disabled="onlyView"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="telefono"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">Fax</label>
                        <Field
                          name="fax"
                          type="text"
                          class="form-control"
                          v-model="impianto.fax"
                          :disabled="onlyView"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="fax"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">E-mail*</label>
                        <Field
                          name="email"
                          type="text"
                          class="form-control"
                          v-model="impianto.email"
                          :disabled="onlyView"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="email"
                        />
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold text-gray-600">Sito web</label>
                        <Field
                          name="web"
                          type="text"
                          class="form-control"
                          v-model="impianto.web"
                          :disabled="onlyView"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="web"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>
        <GestioneCampi
          v-if="isLoaded"
          :campi="items"
          :onlyView="onlyView"
          :readOnly="readOnly"
          :dismessoLookup="dismessoLookup"
          :discipline="discipline"
          @aggiungiCampo="aggiungiCampo"
          @modificaCampo="modificaCampo"
          @removeCampo="removeCampo"
        />
      </form>
    </div>
    <!--end::details View-->
    <div class="text-end" v-if="!onlyView">
      <button v-if="!readOnly" class="badge bg-secondary rounded blueFit fs-5">
        Salva Modifiche
      </button>
    </div>
  </Form>
  <div class="mx-auto my-auto text-center" v-if="!isLoaded">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
</template>

<script>
import { defineComponent, computed, ref, getCurrentInstance } from "vue";
import "vue3-date-time-picker/dist/main.css";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import services from "../../axios/dbManag";
import { useRoute, useRouter } from "vue-router";

import Swal from "sweetalert2/dist/sweetalert2.min.js";
import isEnabled from "@/composables/isEnabled.js";

import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import SelectInput from "../../components/components-fit/utility/inputs/SelectInput.vue";
import ListComuni from "../../components/components-fit/utility/ListComuni.vue";
//import List from "../../components/components-fit/utility/List.vue";

import GestioneCampi from "@/views/impianti/GestioneCampi.vue";

import {
  updateImpianto,
  editCampo,
  deleteCampo,
  addCampo,
} from "../../requests/updateImpianto";

import { useStore } from "vuex";

import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";

export default defineComponent({
  name: "dettaglio-impianto",
  components: {
    SelectInput,
    Form,
    ListComuni,
    Field,
    Datepicker,
    Loading,
    GestioneCampi,
    ErrorMessage,
  },
  props: {
    impiantoSocieta: {
      type: Boolean,
    },
    onlyView: {
      type: Boolean,
    },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const impianto = ref({});
    const campiLocked = ref("");
    const items = ref([]);
    const id = ref(route.params.id_impianto);
    const isLoaded = ref(false);
    const isError = ref(false);
    const boolAggiungiCampo = ref(true);

    const store = useStore();
    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const tipologieImpianti = computed(() =>
      store.getters.getStateFromName("tipo_impianto")
    );
    const proprietaImpianti = computed(() =>
      store.getters.getStateFromName("tipo_proprieta")
    );
    const province = computed(() =>
      store.getters.getStateFromName("istat_province")
    );
    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const keys = ref("ti|tp|ip|d");
    // verifico se le lookup esistono, altrimanti le carico da api
    if (
      !tipologieImpianti.value ||
      !proprietaImpianti.value ||
      !province.value ||
      (province.value && !province.value.length) ||
      !discipline.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const getImpiantoDetail = async () => {
      items.value.splice(0, items.value.length);
      isLoaded.value = false;
      await services.dbManag
        .post(`/anagrafiche/impianti/view`, {
          id: id.value,
        })
        .then((res) => {
          impianto.value = { ...res.data.results[0] };
          campiLocked.value = res.data.campi_Locked;
          res.data.results[0].campi.forEach((campo) => {
            items.value.push(campo);
          });
          changeselectedProvinciaP(impianto.value.id_provincia);
        })
        .then(() => {
          isLoaded.value = true;
        })
        .catch(() => {
          isError.value = true;

          Swal.fire({
            text: "Attenzione! Si è verificato un errore. Riprovare più tardi",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Chiudi",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            router.push({ name: "impianti" });
          });
        });
    };

    getImpiantoDetail();

    const selectedProvincia = ref(false);
    const comuneP = ref("");
    const id_provincia = ref("");
    const id_comune = ref(0);
    const changeselectedProvinciaP = (e) => {
      if (e === undefined || e === null || e === "-") {
        selectedProvincia.value = false;
        impianto.value.id_provincia = null;
        impianto.value.comune = "";
        impianto.value.id_comune = null;
      } else if (e != impianto.value.id_provincia) {
        selectedProvincia.value = true;
        impianto.value.id_provincia = parseInt(e);
        impianto.value.comune = "";
        impianto.value.id_comune = null;
      } else {
        selectedProvincia.value = true;
      }
    };
    const setComuni = (comuneString, id) => {
      if (comuneString.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            attivo: true,
            nomec: comuneString,
            codp: id,
          },
          apiLink: globalApi.COMUNI_ISTAT_LIST,
        });
      }
    };

    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };

    const count = ref([]);

    const inputFocus = ref("");

    const schema = yup.object().shape({
      denominazione: yup.string().required("Obbligatorio"),
      tipo_impianto: yup.number().required("Obbligatorio").nullable(),
      tipo_proprieta: yup.number().required("Obbligatorio").nullable(),
      id_provincia: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable(),
      indirizzo: yup.string().required("Obbligatorio"),
      num_civico: yup.string().required("Obbligatorio"),
      cap: yup
        .string()
        .required("Obbligatorio")
        .nullable()
        .min(5, "Inserire un CAP valido")
        .max(5, "Inserire un CAP valido"),
      telefono: yup.string().required("Obbligatorio"),
      email: yup
        .string()
        .email("Inserire un email valida")
        .required("Obbligatorio"),
      id_comune: yup
        .number()
        .transform((value) => (value == 0 ? null : value))
        .required("Obbligatorio")
        .nullable(),
    });

    const onSubmit = (values) => {
      isLoading.value = true;
      //console.log(JSON.stringify(values, null, 2));
      values = {
        ...values,
        id: impianto.value.id,
      };
      const responseEdit = updateImpianto(values);
      responseEdit.then((value) => {
        isLoading.value = false;
        if (value) {
          Swal.fire({
            html: "Impianto modificato con successo",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          getImpiantoDetail();
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
      });
    };

    const onInvalidSubmit = () => {
      let strMsg = "Compila i campi obbligatori";
      Swal.fire({
        html: strMsg,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      });
    };

    const isLoading = ref(false);

    const modificaCampo = ({ campo, index }) => {
      isLoading.value = true;
      const responseEdit = editCampo(campo);
      responseEdit.then((value) => {
        isLoading.value = false;
        if (value.status == 200) {
          Swal.fire({
            html: "Campo modificato con successo",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
          document.getElementById(`closeModalEditCampo${index}`).click();
          getImpiantoDetail();
        } else {
          const strMsg = ref(
            value.data.message
              ? value.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          Swal.fire({
            html: strMsg.value,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
      });
    };

    const removeCampo = ({ index, id }) =>
      Swal.fire({
        title: "Sei Sicuro?",
        html: "Procedendo il campo veràà eliminato definitivamente.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          const responseDelete = deleteCampo(id);
          responseDelete.then((value) => {
            isLoading.value = false;
            if (value.status == 200) {
              items.value.splice(index, 1);
              Swal.fire({
                html: "Campo eliminato",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            } else {
              const strMsg = ref(
                value.data.message
                  ? value.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
              Swal.fire({
                html: strMsg.value,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "OK",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            }
          });
        }
      });

    const aggiungiCampo = async (obj) => {
      if (JSON.stringify(obj) != "{}") {
        obj.id_Impianto = impianto.value.id;
        isLoading.value = true;
        await addCampo(obj).then((value) => {
          isLoading.value = false;
          if (value.status == 200) {
            Swal.fire({
              html: "Campo aggiunto con successo",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
            document.getElementById("closeModalAddCampo").click();
            getImpiantoDetail();
          } else {
            const strMsg = ref(
              value.data.message
                ? value.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            Swal.fire({
              html: strMsg.value,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          }
        });
      }
    };

    return {
      impianto,
      id,
      campiLocked,
      isLoaded,
      isError,
      items,
      tipologieImpianti,
      proprietaImpianti,
      province,
      discipline,
      isLoading,
      selectedProvincia,
      comuneP,
      id_provincia,
      id_comune,
      changeselectedProvinciaP,
      setComuni,
      spliceComuniIstat,
      inputFocus,
      schema,
      onSubmit,
      onInvalidSubmit,
      modificaCampo,
      count,
      removeCampo,
      aggiungiCampo,
      boolAggiungiCampo,
      isEnabled,
      comuniIstat: computed(() =>
        store.getters.getStateFromName("comuni_istat")
      ),
      dismessoLookup: [
        { id: true, label: "SI" },
        { id: false, label: "NO" },
      ],
      format,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
    };
  },
});
</script>
