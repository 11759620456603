<template>
  <div class="card shadow mb-7">
    <div class="accordion-item">
      <h6 class="accordion-header" id="accordionAnagraficaImpianto-headingFour">
        <button
          class="accordion-button collapsed fw-bolder m-0 p-6"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#accordionAnagraficaImpianto-collapseFive"
          aria-expanded="false"
          aria-controls="accordionAnagraficaImpianto-collapseFive"
        >
          Campi da gioco
        </button>
      </h6>
      <div
        id="accordionAnagraficaImpianto-collapseFive"
        class="accordion-collapse collapse"
        aria-labelledby="accordionAnagraficaImpianto-headingFive"
      >
        <div class="accordion-body p-0 m-0">
          <!--begin::Card body-->
          <div class="card-body p-6">
            <div class="ps-5 pe-5 repeater-form">
              <div
                class="row mb-4"
                v-for="(item, index) in items"
                :id="item.id"
                :key="item.id"
              >
                <SingoloEditCampo
                  v-if="items.length"
                  :campo="item"
                  :onlyView="onlyView"
                  :index="index"
                  :discipline="discipline"
                  :dismessoLookup="dismessoLookup"
                  @modificaCampo="
                    $emit('modificaCampo', { campo: $event, index: index })
                  "
                  @removeCampo="$emit('removeCampo', $event)"
                />
              </div>
              <hr class="mb-4 mt-8 text-gray-600" />
              <div class="fw-bold text-center" v-if="!onlyView">
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_add_campo"
                  v-if="isEnabled('fnImpiantiAddCampo')"
                  class="fw-bold fs-6 text-gray-700"
                >
                  <i class="bi bi-plus text-gray-700 fs-6"></i> aggiungi campo
                </button>
              </div>
              <!-- </div> -->
            </div>
          </div>
          <!--end::Card body-->
        </div>
      </div>
    </div>
  </div>
  <SingoloCampoAdd
    :numberProp="number"
    :discipline="discipline"
    :index="index"
    @eliminaCampo="
      spliceCampo($event);
      rerender = !rerender;
    "
    @aggiungiCampo="$emit('aggiungiCampo', $event)"
  />
</template>

<script>
import { defineComponent, computed, ref, getCurrentInstance } from "vue";
import { Form, Field, FieldArray } from "vee-validate";
import * as yup from "yup";
import SelectInput from "../../components/components-fit/utility/inputs/SelectInput.vue";
import isEnabled from "@/composables/isEnabled.js";
import SingoloCampoAdd from "@/components/components-fit/impianti/SingoloAddCampo.vue";
import SingoloEditCampo from "@/components/components-fit/impianti/SingoloEditCampo.vue";
export default {
  name: "gestioneCampi",
  emits: ["aggiungiCampo", "eliminaCampo", "modificaCampo", "removeCampo"],
  props: {
    campi: {
      type: Array,
      required: true,
    },
    onlyView: {
      type: Boolean,
    },
    readOnly: {
      type: Boolean,
    },
    dismessoLookup: {
      type: Array,
      required: true,
    },
    discipline: {
      type: Array,
      required: true,
    },
  },
  components: {
    SingoloCampoAdd,
    SingoloEditCampo,
  },
  setup(props, { emit }) {
    const items = ref(props.campi);
    const count = ref([]);

    const schema = yup.object().shape({
      campi: yup.array().of(
        yup.object({
          nome_Campo: yup.string().required("Obbligatorio"),
          dismesso: yup.boolean().required("Obbligatorio"),
          id_Disciplina: yup
            .number()
            .required("Obbligatorio")
            .transform((value) => (isNaN(value) ? undefined : value)),
        })
      ),
    });
    const spliceCampo = (e) => {
      count.value.splice(e, 1);
    };
    const rerender = ref(false);
    return { items, count, isEnabled, schema, spliceCampo, rerender };
  },
};
</script>

<style></style>
