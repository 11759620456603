<template>
  <div class="row" v-if="item">
    <div class="col-md-5 align-self-center">
      <label class="fw-bold text-gray-600" label-for="descrizione"
        >Descrizione*</label
      >
      <input
        :name="`nome${index}`"
        type="text"
        class="form-control"
        :value="item.nome_Campo"
        disabled
      />
    </div>
    <div class="col-md-3 align-self-center">
      <label class="fw-bold text-gray-600" label-for="disciplina"
        >Disciplina*
      </label>
      <SelectInput
        :name="`id${index}`"
        :options="discipline"
        :value="item.id_Disciplina"
        disabled
      />
    </div>
    <div class="col-md-2 align-self-center">
      <label class="fw-bold text-gray-600" label-for="dismesso">Dismesso</label>
      <SelectInput
        :name="`Dismesso${index}`"
        :options="dismessoLookup"
        :value="item.dismesso"
        disabled
      />
    </div>
    <div v-if="!onlyView" class="col-md-2 fw-bold text-center mt-5">
      <button
        type="button"
        data-bs-toggle="modal"
        :data-bs-target="`#modal_edit_campo${index}`"
        v-if="isEnabled('fnImpiantiCampiSavecampo')"
        class="badge bg-light rounded fs-5 me-6"
      >
        <i class="bi bi-pen fs-5"></i>
      </button>
      <button
        v-if="isEnabled('fnImpiantiDeleteCampo')"
        class="badge bg-light-danger rounded fs-5"
        @click.prevent="$emit('removeCampo', { index: index, id: item.id })"
      >
        <i class="bi bi-trash text-danger fs-5"></i>
      </button>
    </div>
    <SingoloEditCampoModal
      :campo="campo"
      @modificaCampo="$emit('modificaCampo', $event)"
      :discipline="discipline"
      :dismessoLookup="dismessoLookup"
      :index="index"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import SelectInput from "../utility/inputs/SelectInput.vue";
import isEnabled from "@/composables/isEnabled.js";
import SingoloEditCampoModal from "./SingoloEditCampoModal.vue";
export default {
  name: "SingoloCampoEdit",
  emits: ["modificaCampo", "removeCampo"],
  props: {
    campo: {
      type: Object,
      required: true,
    },
    onlyView: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    discipline: {
      type: Array,
      required: true,
    },
    dismessoLookup: {
      type: Array,
      required: true,
    },
  },
  components: {
    SelectInput,
    SingoloEditCampoModal,
  },
  setup(props) {
    const item = ref(props.campo);

    return { item, isEnabled };
  },
};
</script>

<style></style>
