import services from "../axios/dbManag";

export const updateImpianto = (impianto) => {
  return services.dbManag
    .post("/anagrafiche/impianti/edit", {
      ...impianto,
    })
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const editCampo = (campo) => {
  return services.dbManag
    .post("/anagrafiche/impianti/campi/edit", {
      ...campo,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const deleteCampo = (id) => {
  return services.dbManag
    .post("/anagrafiche/impianti/campi/del", {
      id: id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addCampo = (campo) => {
  return services.dbManag
    .post("/anagrafiche/impianti/campi/add", {
      ...campo,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
