<template>
  <Form
    @submit="onSubmit"
    :validation-schema="schema"
    class="row"
    v-if="info_campo"
  >
    <div class="modal fade" tabindex="-10" :id="`modal_edit_campo${index}`">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Modifica Campo</h5>
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              :id="`closeModalEditCampo${index}`"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-5">
                <label class="fw-bold text-gray-600" label-for="descrizione"
                  >Descrizione*</label
                >
                <Field name="id" type="hidden" :value="info_campo.id" />
                <Field
                  name="nome_Campo"
                  type="text"
                  class="form-control"
                  :value="info_campo.nome_Campo"
                />
                <ErrorMessage
                  class="help-message"
                  style="color: red"
                  name="nome_Campo"
                />
              </div>
              <div class="col-md-4">
                <label class="fw-bold text-gray-600" label-for="disciplina"
                  >Disciplina*</label
                >
                <SelectInput
                  :options="discipline"
                  :value="info_campo.id_Disciplina"
                  name="id_Disciplina"
                />
              </div>
              <div class="col-md-3">
                <label class="fw-bold text-gray-600" label-for="dismesso"
                  >Dismesso</label
                >
                <SelectInput
                  :options="dismessoLookup"
                  :value="info_campo.dismesso"
                  name="dismesso"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
            >
              Salva
            </button>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import SelectInput from "../utility/inputs/SelectInput.vue";
export default {
  name: "SingoloCampoModal",
  emits: ["modificaCampo"],
  props: {
    campo: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    discipline: {
      type: Array,
      required: true,
    },
    dismessoLookup: {
      type: Array,
      required: true,
    },
  },
  components: {
    Form,
    Field,
    SelectInput,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const schema = yup.object().shape({
      id: yup.number(),
      nome_Campo: yup.string().required("Obbligatorio"),
      dismesso: yup.boolean().nullable(),
      id_Disciplina: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
    });

    const onSubmit = (values) => {
      emit("modificaCampo", values);
    };

    const info_campo = ref(props.campo);

    return { onSubmit, schema, info_campo };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.form-check-input[type="radio"] {
  margin-top: 0.15rem !important;
  padding: 0px;
}
</style>
