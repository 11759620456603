<template>
  <Form
    @submit="onSubmit"
    :validation-schema="schema"
    @invalid-submit="onInvalidSubmit"
  >
    <div class="modal fade" tabindex="-10" id="modal_add_campo">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Aggiungi Campo</h5>

            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-secondary"
              style="border: 0; !important"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeModalAddCampo"
            >
              <i class="bi bi-x-lg"> </i>
            </div>
            <!--end::Close-->
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <label class="fw-bold text-gray-600" label-for="nome_Campo"
                  >Descrizione*</label
                >
                <Field
                  :name="`nome_Campo`"
                  type="text"
                  class="form-control"
                  @input="nome_Campo = $event.target.value"
                />
                <ErrorMessage
                  class="help-message"
                  style="color: red"
                  :name="`nome_Campo`"
                />
              </div>
              <div class="col-md-4">
                <label class="fw-bold text-gray-600" label-for="id_Disciplina"
                  >Disciplina*</label
                >
                <SelectInput
                  :name="`id_Disciplina`"
                  :id="`id_Disciplina`"
                  :options="discipline"
                  @changeSelect="id_Disciplina = $event"
                />
              </div>
              <div class="col-md-3"></div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="me-3 badge bg-light-danger rounded text-danger fs-6"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Chiudi
            </button>
            <button
              type="submit"
              class="badge bg-secondary rounded blueFit fs-6"
            >
              Salva
            </button>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import SelectInput from "../utility/inputs/SelectInput.vue";
export default {
  name: "SingoloCampo",
  emits: ["aggiungiCampo", "eliminaCampo"],
  props: {
    discipline: {
      type: Array,
      required: true,
    },
  },
  components: {
    Form,
    Field,
    SelectInput,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const onSubmit = (values) => {
      emit("aggiungiCampo", values);
    };

    const schema = yup.object().shape({
      nome_Campo: yup.string().required("Obbligatorio"),
      id_Disciplina: yup
        .number()
        .required("Obbligatorio")
        .transform((value) => (isNaN(value) ? undefined : value)),
    });
    return { onSubmit, schema };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1200px) {
  .modal-dialog {
    max-width: 55%;
  }
}

@media (max-width: 991.98px) {
  .modal-dialog {
    max-width: 65%;
  }
}

.form-check-input[type="radio"] {
  margin-top: 0.15rem !important;
  padding: 0px;
}
</style>
